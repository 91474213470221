import { BaseElement } from '../../01-base/BaseElement';

class AutostartFunction extends BaseElement {
    hooks() {
        return {
            connected: () => {
                this.contextPath = this.url.replace('/services/autoplay', '');
                this.getAutoplay();
            },
        };
    }

    properties() {
        return {
            url: '',
            autoplay: {},
        };
    }

    getAutoplay() {
        fetch(this.url)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                this.autoplay = response;
                this.checkAutoplay();
            });
    }

    checkAutoplay() {
        if (Object.keys(this.autoplay).length > 0) {
            const autoplayParam = location.search.split('dgvautoplay=')[1];
            if (autoplayParam == 1) {
                window.open(this.autoplay.url, this.autoplay.label);
            }
        }
        let url = this.removeParam('dgvautoplay', location.href);
        window.history.replaceState({}, document.title, url);
    }

    removeParam(key, sourceURL) {
        let rtn = sourceURL.split('?')[0],
            param,
            params_arr = [],
            queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
        if (queryString !== '') {
            params_arr = queryString.split('&');
            for (let i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split('=')[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + '?' + params_arr.join('&');
        }
        return rtn;
    }
}

customElements.define('autostart-function', AutostartFunction);
